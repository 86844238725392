import React, { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { MESSAGE_UPLOAD_FILE_MAX_COUNT } from '../../../constants/common';
import useAlert from '../../../hooks/useAlert';
import useMessageInput from '../../../hooks/useMessageInput';
import { UploadToS3Response } from '../../../libs/s3';
import { CustomMessageType } from '../../../types/common';

import { mediaApi } from 'apis/media';
import { ReactComponent as InfoIcon } from 'assets/icons/ic-info.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/ic-upload-purple.svg';
import Button from 'components/jayden/Button';

import { useCurrentChannel } from 'hooks/use-current-channel';

import { _changeS3Form } from 'utils/s3-form';

const fileTypes = ['mp4', 'mov', 'avi', 'wmv', 'mkv', 'flv'];

const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB

const ChatMessageAttachmentVideo = () => {
  const navigate = useNavigate();
  const { data: currentChannel } = useCurrentChannel();
  const { sendMessageToChannel } = useMessageInput();
  const { show, close } = useAlert();
  const [attachments, setAttachments] = useState<UploadToS3Response[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const { t } = useTranslation();

  const onConfirm = async () => {
    if (!currentChannel) return;

    await sendMessageToChannel(currentChannel, {
      message: 'upload video',
      data: JSON.stringify(attachments),
      customType: CustomMessageType.VIDEO
    });
    navigate(-1);
  };

  const handleDrop = async (files: any) => {
    if (!currentChannel) return;
    if (files.length === 0) return;

    if (files.length > MESSAGE_UPLOAD_FILE_MAX_COUNT) {
      show({
        content: t('chat.attachPopup.video.validate.upTo12'),
        buttons: [
          {
            title: t('common.button.confirm'),
            async onClick() {
              close();
            }
          }
        ]
      });
      return;
    }

    setIsUploading(true);

    const testArray: UploadToS3Response[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file.size > MAX_FILE_SIZE) {
        show({
          content: t('chat.attachPopup.video.guide'),
          buttons: [
            {
              title: t('common.button.confirm'),
              async onClick() {
                close();
              }
            }
          ]
        });
        setIsUploading(false);
        return;
      }

      const formData = new FormData();
      formData.append('file', file);

      const result = await mediaApi.postVideoUpload(formData);
      testArray.push(_changeS3Form(result, file.type));
    }
    setAttachments((prev) => [...prev, ...testArray]);
    setIsUploading(false);
  };

  return (
    <div className="h-[calc(100%-52px)] pt-5">
      <div className="h-full overflow-y-auto">
        <FileUploader handleChange={handleDrop} name="file" multiple types={fileTypes}>
          <div className="mx-auto flex w-full cursor-pointer flex-col items-center justify-center rounded-3 border border-gray-300 bg-gray-200 py-[43px]">
            <UploadIcon className="size-[60px]" />
            <div className="text-center text-16 font-semibold text-gray-700">
              {t('chat.attachPopup.video.dragAndDrop')}
            </div>
          </div>
        </FileUploader>

        <div className="h-2" />
        <div className="flex items-center gap-1 text-13 text-gray-700">
          <InfoIcon className="" />
          {t('chat.attachPopup.video.guide')}
        </div>
        {/* 비디오업로드후에는 자동 썸네일 적용 */}
        {attachments.length > 0 && (
          <>
            <div className="mb-2 mt-6 text-14 font-medium text-black-title">
              {t('chat.attachPopup.video.thumbnail')}
            </div>
            <div className="w-full rounded-2">
              {attachments.map((attachment, index) => {
                return (
                  <video key={index.toString()} playsInline src={attachment.cloudFrontUrl} width={68} height={68} />
                );
              })}
            </div>
          </>
        )}

        <div className="h-5" />
      </div>

      <Button
        size="lg"
        className="w-full text-center"
        color="primary"
        onClick={onConfirm}
        disabled={attachments.length === 0}
      >
        {isUploading ? t('chat.attachPopup.button.uploading') : t('common.button.confirm')}
      </Button>
    </div>
  );
};
export default ChatMessageAttachmentVideo;
