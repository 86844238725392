import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseChannel } from '@sendbird/chat';
import { GroupChannelHandler } from '@sendbird/chat/groupChannel';
import { BaseMessage, UserMessage } from '@sendbird/chat/message';

import { UploadToS3Response } from '../../../libs/s3';
import getSendBird, { fetchMessages } from '../../../libs/sendbird';
import { CustomMessageType } from '../../../types/common';
import Gallery from '../Gallery';

import { useCurrentChannel } from 'hooks/use-current-channel';

const ChatRoomSharedFileList = () => {
  const { data: channel, url } = useCurrentChannel();

  const [images, setImages] = useState<UploadToS3Response[]>([]);
  const [messages, setMessages] = useState<UserMessage[]>([]);
  const [focusImage, setFocusImage] = useState<UploadToS3Response | null>(null);
  const [isOpenedGallery, setIsOpenedGallery] = useState(false);
  const { t } = useTranslation();

  const overQuantity = useMemo(() => {
    return images.slice(8).length;
  }, [images]);

  const onOpenGallery = useCallback(
    (image: UploadToS3Response) => () => {
      setIsOpenedGallery(true);
      setFocusImage(image);
    },
    []
  );

  const onCloseGallery = useCallback(() => {
    setIsOpenedGallery(false);
    setFocusImage(null);
  }, []);

  /**
   * 앱 요청으로 message -> data로 옮김
   */
  const init = useCallback(async () => {
    const messages = (await fetchMessages(url, {
      customTypesFilter: [CustomMessageType.IMAGE, CustomMessageType.VIDEO],
      limit: 8
    })) as UserMessage[];

    setImages(
      messages.flatMap<UploadToS3Response>((message) => {
        try {
          if (typeof message.message === 'string' && message.data) {
            return JSON.parse(message.data);
          }
          return JSON.parse(message.message);
        } catch {
          return [];
        }
      })
    );
    setMessages(messages);
  }, [channel]);

  const onMessageReceived = useCallback(async (receivedChannel: BaseChannel, message: BaseMessage) => {
    if (message.customType === CustomMessageType.IMAGE || message.customType === CustomMessageType.VIDEO) {
      init();
    }
  }, []);

  const onMessageDeleted = useCallback(async (receivedChannel: BaseChannel, messageId: number) => {
    const found = messages.find((message) => message.messageId === messageId);
    if (found) {
      init();
    }
  }, []);

  useEffect(() => {
    init();
  }, [channel]);

  useEffect(() => {
    async function handler(event: CustomEvent<UserMessage>) {
      if (event.detail.customType === CustomMessageType.IMAGE || event.detail.customType === CustomMessageType.VIDEO) {
        init();
      }
    }

    window.addEventListener('sentNewMessage', handler as unknown as EventListenerOrEventListenerObject);
    return () => {
      window.removeEventListener('sentNewMessage', handler as unknown as EventListenerOrEventListenerObject);
    };
  }, []);

  useEffect(() => {
    const handler = new GroupChannelHandler({
      onMessageReceived,
      onMessageDeleted
    });
    const handlerId = `sharedFileHandler`;

    getSendBird().groupChannel.addGroupChannelHandler(handlerId, handler);

    return () => {
      getSendBird().groupChannel.removeGroupChannelHandler(handlerId);
    };
  }, [onMessageReceived]);

  return (
    <>
      {isOpenedGallery === true && <Gallery images={images} onClose={onCloseGallery} focusImage={focusImage} />}
      <div>
        <span className="text-13 font-medium text-gray-600">
          {t('chat.channelInfo.sharedFiles.label')} - {images.length}
        </span>
        <ul className="mt-2 flex w-[260px] shrink-0 flex-wrap gap-1">
          {images.slice(0, 8).map((image, index) => {
            return (
              <li
                key={index}
                onClick={onOpenGallery(image)}
                className="relative size-[62px] shrink-0 overflow-hidden rounded-2"
              >
                {image.contentType.indexOf('image') > -1 && (
                  <img className="size-full rounded-2 object-cover hover:cursor-pointer" src={image?.cloudFrontUrl} />
                )}
                {image.contentType.indexOf('video') > -1 && (
                  <video
                    width={62}
                    height={62}
                    controls={false}
                    autoPlay={false}
                    style={{ zIndex: 1 }}
                    className="rounded-2"
                  >
                    <source src={`${image.cloudFrontUrl}#t=0.1`} type={image.contentType} />
                  </video>
                )}

                {index === 7 && (
                  <div className="absolute inset-0 rounded-2 bg-black/50">
                    <span className="flex h-full items-center justify-center text-14 text-white">{overQuantity}+</span>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default ChatRoomSharedFileList;
