import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PostMessagePath, RequestCommand, ResponseCommand, ResponseCommandMap } from '../types/postMessageCommand';

import useIframePostMessageListener from './useIframePostMessageListener';
import usePostMessage from './usePostMessage';

import { i18nDayjsLanguageCode } from 'constants/date.type';
import { AuthStorage } from 'constants/storage';

import { getStorage } from 'utils/storage';

export const voteUrl = process.env.REACT_APP_VOTE_URL;

type Props = {
  ref: React.RefObject<HTMLIFrameElement>;
  commandMap?: ResponseCommandMap;
  context?: any;
};

const useVoteIframe = ({ ref, commandMap: _commandMap, context }: Props) => {
  const postMessage = usePostMessage(ref);
  const { i18n } = useTranslation();
  const language = useMemo(() => i18nDayjsLanguageCode[i18n.language as 'kr' | 'jp' | 'en'], [i18n.language]);

  const init = (event: MessageEvent) => {
    if (event.data?.from !== PostMessagePath.Vote) return;
    const accessToken = getStorage(AuthStorage.ACCESS_TOKEN);
    postMessage(RequestCommand.Initialize, { accessToken, data: context, language });
  };

  const commandMap = useMemo<ResponseCommandMap>(
    () => new Map([[ResponseCommand.Ready, init], ...(_commandMap ?? [])]),
    [_commandMap]
  );

  useIframePostMessageListener({ commandMap, ref });
};

export default useVoteIframe;
