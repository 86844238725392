import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { useRecoilState } from 'recoil';

import AddUserModal from '../add-user-modal';
import EnterAtOnceModal from '../enter-at-once-modal';

import SelectedUserInput from './selected-user-input';

import { CheckIcon, PlusIcon } from 'assets/iicon';
import { multiCoinSendMapState } from 'components/wallet/recoil';
import useModalStack from 'routers/modal/useModalStack';

interface UserSelectProps {
  userList: any[];
  setUserList: Dispatch<SetStateAction<any[]>>;
  channelId: string;
  singleSelect?: boolean;
}

const UserSelect = (props: UserSelectProps) => {
  const { userList, setUserList, channelId, singleSelect = false } = props;

  const { t } = useTranslation();

  const modalStack = useModalStack();
  const [_, setMap] = useRecoilState(multiCoinSendMapState);

  const onClickAddUser = () => {
    modalStack.push({
      Component: AddUserModal,
      componentProps: {
        initialData: userList,
        onSubmit: (list: any[]) => {
          setMap((prev) => {
            const newMap = prev.filter((item) => list.some((user) => user.id === item.id));
            return newMap;
          });
          setUserList(list);
        },
        channelId,
        singleSelect
      },
      key: 'send-coin-add-user-modal'
    });
  };

  const onClickRemove = (v: any) => {
    setUserList((prev) => prev.filter((item) => item.id !== v.id));

    setMap((prev) => {
      return prev.filter((m) => v.id !== m.id);
    });
  };

  const onOpenEnterAtOnce = () => {
    modalStack.push({
      key: 'enter-at-once-modal',
      Component: EnterAtOnceModal,
      componentProps: {
        userList: userList
      }
    });
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center justify-between">
        <h3 className="text-16 font-semibold">
          <span className="text-20 text-primary-100">{userList.length}</span>
          &nbsp;User
        </h3>

        <div className="flex items-center gap-1">
          <button
            className="flex items-center rounded-1 border border-gray-500 bg-gray-100 px-2 py-[4.5px] text-13 text-black-title disabled:bg-gray-100 disabled:text-gray-500"
            onClick={onOpenEnterAtOnce}
            disabled={singleSelect || userList.length === 0}
          >
            <CheckIcon className="size-4" />
            {t('sendCoin.enterAtOnce.title')}
          </button>

          <button
            className="flex items-center rounded-1 border border-gray-500 bg-gray-100 px-2 py-[4.5px] text-13 text-black-title disabled:bg-gray-100 disabled:text-gray-500"
            onClick={onClickAddUser}
          >
            <PlusIcon className="size-4" />
            {t('sendCoin.addUser.button')}
          </button>
        </div>
      </div>

      <div className="scroll-none max-h-[328px] min-h-16 overflow-y-scroll rounded-2 border border-gray-300 bg-white p-5">
        {userList.map((v) => {
          return <SelectedUserInput key={v.id} v={v} onClickRemove={onClickRemove} />;
        })}
      </div>
    </div>
  );
};

export default UserSelect;
