import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { Outlet } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import Alert from '../../components/alert/Alert';
import useAlert from '../../hooks/useAlert';
import useCurrentUser, { UserStatus } from '../../hooks/useCurrentUser';
import { getDMMember } from '../../libs/helper';
import chatRoomExpandedState from '../../store/atoms/chatRoomExpandedState';

import ChannelInfo from './components/ChannelInfo';
import ChannelList from './components/ChannelList';
import ChatRoom from './components/ChatRoom';
import DMChannelInfo from './components/DMChannelInfo';

import { ChannelType } from 'apis/types/chat.type';
import Loader from 'components/jayden/Loader';

import { useCurrentChannel } from 'hooks/use-current-channel';

const ChatMain = () => {
  const { t } = useTranslation();

  const { data: channel } = useCurrentChannel();

  const chatRoomExpanded = useRecoilValue(chatRoomExpandedState);

  const { currentUser, updateUserState } = useCurrentUser();
  const { alerts } = useAlert();

  // 유저 접속 상태 관리
  useIdleTimer({
    crossTab: true,
    leaderElection: true,
    timeout: 1000 * 60 * 5, // 5분
    onIdle() {
      updateUserState(UserStatus.AWAY);
    },
    onActive() {
      updateUserState(UserStatus.ONLINE);
    }
  });

  // DM채널인 경우 join 하기
  useEffect(() => {
    if (channel?.customType !== ChannelType.DM) return;
  }, [channel]);

  const toUserId = useMemo(() => {
    if (!channel) {
      return null;
    }

    return getDMMember(channel, currentUser?.userId);
  }, [channel, currentUser]);

  if (!currentUser) {
    return (
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <Loader className="mt-[0px]" />
      </div>
    );
  }

  return (
    <>
      {/* <LeftMenu /> */}
      <ChannelList />
      {channel ? (
        <>
          <ChatRoom channel={channel} />

          {chatRoomExpanded === false && (
            <>
              {toUserId !== null && <DMChannelInfo channelUrl={channel.channelUrl} toUserId={toUserId} />}
              {toUserId === null && <ChannelInfo />}
            </>
          )}
        </>
      ) : (
        <div className="col-span-2 row-span-1 flex flex-col items-center justify-center bg-gray-100">
          <img src="/img/com/img_unsmile3_gr.png" alt="no result" className="mb-4 size-[100px]" />
          <h1 className="mb-1.5 text-18 font-semibold tracking-[-0.36px] text-black-title">
            {t('chat.emptyChannel.title')}
          </h1>
          <p className="text-13 text-gray-700">{t('chat.emptyChannel.description')}</p>
        </div>
      )}
      <Outlet />
      {alerts.map((alert, alertIndex) => (
        <Alert key={alertIndex} {...alert} />
      ))}
    </>
  );
};

export default ChatMain;
