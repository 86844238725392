import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import CretaLogo from 'assets/images/creta.png';
import Button from 'components/jayden/Button';
import GroupSendCoinModal from 'components/wallet/modal/group-send-coin-modal';
import { SuperClubUserInterface } from 'libs/ApiRepository';
import useAlert from 'routers/modal/useAlert';
import useModalStack from 'routers/modal/useModalStack';

import useUserMe from 'hooks/use-user-me';

import { isWebView } from 'utils/wallet';

interface SendCoinButtonProps {
  user: SuperClubUserInterface;
}

const SendCoinButton = (props: SendCoinButtonProps) => {
  const { user } = props;
  const { t } = useTranslation();
  const myInfo = useUserMe();

  const toAddress = user.wallet_address;

  const modalStack = useModalStack();

  const primaryWalletAddress = myInfo?.wallet_address;
  const cretaWalletAddress = myInfo?.wallet_address_creta;

  const alert = useAlert();

  const openSendCoinModal = () => {
    switch (true) {
      case isWebView():
        if (!myInfo?.wallet_address_creta) {
          alert.open({
            contents: (
              <>
                <p className="text-16 text-black-body">
                  <Trans i18nKey="chat.sendCoin.block.alert.contents">
                    <span className="font-medium text-primary-100" />
                  </Trans>
                </p>
              </>
            ),
            submitText: t('chat.channelHeader.tooltip.setting'),
            submit: () => {
              window.parent.postMessage(
                {
                  type: 'NAVIGATE',
                  path: '/user-profile/wallet/'
                },
                '*'
              );
            }
          });
        } else {
          modalStack.push({
            key: 'send-coin-sns',
            Component: GroupSendCoinModal,
            componentProps: {
              initialData: [user],
              isDefaultCreta: isWebView() ? true : primaryWalletAddress === cretaWalletAddress,
              singleSelect: isWebView()
            }
          });
        }
        break;

      default:
        if (!myInfo?.wallet_address) {
          alert.open({
            contents: (
              <>
                <p className="text-16 text-black-body">
                  <Trans i18nKey="chat.sendCoin.block.alert.contents">
                    <span className="font-medium text-primary-100" />
                  </Trans>
                </p>
              </>
            ),
            submitText: t('chat.channelHeader.tooltip.setting'),
            submit: () => {
              window.open(`${process.env.REACT_APP_MAIN_URL}/user-profile/wallet/`);
            }
          });
          return;
        }

        modalStack.push({
          key: 'send-coin-sns',
          Component: GroupSendCoinModal,
          componentProps: {
            initialData: [user],
            isDefaultCreta: isWebView() ? true : primaryWalletAddress === cretaWalletAddress,
            singleSelect: isWebView()
          }
        });
    }
  };

  const tooltipText = () => {
    if (myInfo?.wallet_address && toAddress) {
      return t('chat.miniProfile.sendCoin.button');
    } else if (!myInfo?.wallet_address) {
      return t('chat.miniProfile.sendCoin.disabled.me');
    } else return t('chat.miniProfile.sendCoin.disabled.other');
  };

  return (
    <div className="">
      <Button
        color="default"
        className="flex items-center justify-center gap-1 whitespace-nowrap text-15"
        size="md"
        onClick={openSendCoinModal}
        disabled={!toAddress || !myInfo?.wallet_address}
        data-tooltip-id="mini-profile-coin-status-tooltip"
        data-tooltip-content={tooltipText()}
      >
        <img src={CretaLogo} className="size-6" />
        {t('chat.inputMore.label.coins')}
      </Button>
      <Tooltip
        id="mini-profile-coin-status-tooltip"
        place="top"
        style={{
          fontSize: '12px'
        }}
      />
    </div>
  );
};

export default SendCoinButton;
