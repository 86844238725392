import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRecoilState, useRecoilValue } from 'recoil';

import { cretaWalletState, metamaskWalletState, multiCoinSendMapState } from '../../recoil';
import SendConfirmModal from '../send-confirm-modal';

import CretaSelect from './components/creta-select';
import MetamaskSelect from './components/metamask-select';
import { useGetBalanceCreta, useGetBalanceMetamask, useResetRecoil } from './use-effect-hooks';
import UserSelect from './user-select';

import { CretaLogo } from 'assets/images';
import ModalLayout from 'components/alert/modal-layout';
import Button from 'components/jayden/Button';
import { CRETA_META } from 'components/wallet/constants';
import { numberUI } from 'components/wallet/util';
import useModalStack from 'routers/modal/useModalStack';

import useUserMe from 'hooks/use-user-me';

import { isWebView } from 'utils/wallet';

interface GroupSendCoinModalProps {
  close?: () => void;
  channelId: string;
  initialData?: any[];
  isDefaultCreta?: boolean;

  singleSelect?: boolean;
}

const GroupSendCoinModal = (props: GroupSendCoinModalProps) => {
  const { close, channelId, initialData = [], isDefaultCreta = true, singleSelect = false } = props;

  const { t } = useTranslation();
  const myInfo = useUserMe();
  const modalStack = useModalStack();

  const cretaWallet = myInfo?.wallet_address_creta;
  const metaMaskWallet = myInfo?.wallet_address_metamask;

  const creta = useRecoilValue(cretaWalletState);
  const metamask = useRecoilValue(metamaskWalletState);
  const [map, setMap] = useRecoilState(multiCoinSendMapState);

  /**
   * useEffect 훅들
   */
  useResetRecoil();
  useGetBalanceCreta(cretaWallet);
  useGetBalanceMetamask(metaMaskWallet);

  const [userList, setUserList] = useState<any[]>(initialData);

  const [currentWallet, setCurrentWallet] = useState<'CRETA' | 'METAMASK'>(isDefaultCreta ? 'CRETA' : 'METAMASK');
  const isCreta = currentWallet === 'CRETA';
  const [isOpen, setIsOpen] = useState(false);

  const openConfirmModal = () => {
    modalStack.push({
      key: 'send-coin-confirm-modal',
      Component: SendConfirmModal,
      componentProps: {
        userList: userList,
        isCreta
      }
    });
  };

  /**
   * 크레타 <-> 메타마스크 바꿀때마다 초기화
   */
  useEffect(() => {
    if (currentWallet === 'CRETA') return;

    const slice = userList?.slice(0, 1)[0];
    setMap((prev) => prev.filter((obj) => slice.id === obj.id));

    if (slice) setUserList([slice]);
  }, [currentWallet]);

  return (
    <ModalLayout title={t('sendCoin.indexTitle.title')} close={close}>
      <div className="bg-gray-200 pr-2">
        <div className="modal-gray-scroll flex max-h-[476px] w-full flex-col overflow-scroll py-5 pl-6 pr-4">
          <div className="flex w-full flex-col">
            <LabeledItem label={t('setting.wallet.send.label.yourAddress')}>
              <div
                className="relative cursor-pointer hover:bg-gray-200"
                onClick={() => {
                  if (isWebView()) return;
                  if (cretaWallet && metaMaskWallet) setIsOpen(!isOpen);
                }}
              >
                {isCreta && (
                  <div className="rounded-2 border border-gray-300 bg-gray-100">
                    <CretaSelect address={cretaWallet} arrow={!!cretaWallet && !!metaMaskWallet} isOpen={isOpen} />
                  </div>
                )}
                {!isCreta && (
                  <div className="rounded-2 border border-gray-300 bg-gray-100">
                    <MetamaskSelect
                      address={metaMaskWallet}
                      arrow={!!cretaWallet && !!metaMaskWallet}
                      isOpen={isOpen}
                    />
                  </div>
                )}

                {isOpen && (
                  <div className="absolute z-50 w-full rounded-2 border border-gray-300 bg-gray-100 ">
                    <CretaSelect address={cretaWallet} onClick={() => setCurrentWallet('CRETA')} />
                    <MetamaskSelect address={metaMaskWallet} onClick={() => setCurrentWallet('METAMASK')} />
                  </div>
                )}
              </div>
              <div className="h-2" />

              <div className="relative">
                <div className="absolute left-4 top-1/2 size-6 -translate-y-1/2">
                  <img draggable={false} className="size-6" src={CretaLogo} alt="1" />
                </div>
                <div className="w-full rounded-2 border border-gray-300 bg-gray-100 px-4 py-[13px] pl-12 text-16 text-primary-100 disabled:text-gray-700">
                  <span className="text-18 font-semibold">
                    {numberUI(
                      (
                        (isCreta ? creta : metamask).tokens.find(
                          (item) => item.contractAddress === CRETA_META.contractAddress
                        ) || {}
                      )?.balance
                    )}
                    &nbsp;
                  </span>
                  CRETA
                </div>
              </div>
            </LabeledItem>

            <Divider />

            <UserSelect
              userList={userList}
              setUserList={setUserList}
              channelId={channelId}
              singleSelect={singleSelect || !isCreta}
            />

            <Divider />

            <LabeledItem label={t('sendCoin.totalVolume')}>
              <div className="flex items-center justify-end rounded-2.5 bg-secondary-100 px-6 py-[18px]">
                <p className="text-18 text-white">
                  <span className="text-24 font-semibold">
                    {numberUI(map.reduce((acc, cur) => acc + Number(cur.amount), 0))}
                  </span>
                  &nbsp;CRETA
                </p>
              </div>
            </LabeledItem>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-2 p-5">
        <Button color="default" size="lg" className="flex-1" center onClick={close}>
          {t('common.comment.input.cancel')}
        </Button>
        <Button
          color="primary"
          size="lg"
          className="flex-1"
          center
          onClick={openConfirmModal}
          disabled={map.length === 0 || map.length !== userList.length}
        >
          {t('common.card.button.next')}
        </Button>
      </div>
    </ModalLayout>
  );
};

export default GroupSendCoinModal;

const LabeledItem = ({ label, children }: { label: string; children: React.ReactNode }) => {
  return (
    <div className="flex flex-col">
      <h3 className="mb-3 text-16 font-semibold text-black-title">{label}</h3>
      {children}
    </div>
  );
};

const Divider = () => <div className="my-5 h-px w-full bg-gray-300" />;
