import React, { useCallback, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { MESSAGE_UPLOAD_FILE_MAX_COUNT } from '../../../constants/common';
import useAlert from '../../../hooks/useAlert';
import useMessageInput from '../../../hooks/useMessageInput';
import { IMAGE_TYPES, getIsImage } from '../../../libs/helper';
import { UploadToS3Response } from '../../../libs/s3';
import { CustomMessageType } from '../../../types/common';

import { mediaApi } from 'apis/media';
import { ReactComponent as CloseIcon } from 'assets/icons/ic-close.svg';
import { ReactComponent as FileIcon } from 'assets/icons/ic-file.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/ic-info.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/ic-upload-purple.svg';
import Button from 'components/jayden/Button';
import classNames from 'components/styled/util';

import { useCurrentChannel } from 'hooks/use-current-channel';

import { _changeS3Form } from 'utils/s3-form';

const FILE_MAX_SIZE = 20 * 1024 * 1024; // 20MB

const ChatMessageAttachmentUpload = () => {
  const navigate = useNavigate();
  const { sendMessageToChannel } = useMessageInput();
  const { data: channel } = useCurrentChannel();
  const [attachments, setAttachments] = useState<UploadToS3Response[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [multiple, _setMultiple] = useState<boolean>(true);
  const { show, close } = useAlert();
  const [_availableFileTypes, setAvailableFileTypes] = useState<string[] | undefined>(undefined);
  const { t } = useTranslation();

  const onConfirm = async () => {
    if (!channel) {
      return;
    }
    const images = attachments.filter((attachment) => getIsImage(attachment.contentType));
    const files = attachments.filter((attachment) => !getIsImage(attachment.contentType));

    if (images.length > 0) {
      await sendMessageToChannel(channel, {
        message: 'upload image',
        data: JSON.stringify(images),
        customType: CustomMessageType.IMAGE
      });
    }

    if (files.length > 0) {
      await sendMessageToChannel(channel, {
        message: 'upload file',
        data: JSON.stringify(files),
        customType: CustomMessageType.FILE
      });
    }
    navigate(-1);
  };

  const onRemoveFile = useCallback(
    (index: number) => async () => {
      setAttachments((state) => [...state.slice(0, index), ...state.slice(index + 1)]);
    },
    []
  );

  useEffect(() => {
    if (attachments.length > 0) {
      const isExistsImage = attachments.find((attachment) => getIsImage(attachment.contentType));
      if (isExistsImage) {
        setAvailableFileTypes(IMAGE_TYPES.map((type) => type.replace('image/', '')));
      } else {
        setAvailableFileTypes(undefined);
      }
    }
  }, [attachments]);

  const handleDrop = async (files: any) => {
    if (files.length === 0) return;

    if (files.length > MESSAGE_UPLOAD_FILE_MAX_COUNT) {
      show({
        content: t('chat.attachPopup.upload.validate.upTo12'),
        buttons: [
          {
            title: t('common.button.confirm'),
            async onClick() {
              close();
            }
          }
        ]
      });
      return;
    }

    setIsUploading(true);

    const testArray: UploadToS3Response[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file.size > FILE_MAX_SIZE) {
        show({
          content: t('chat.attachPopup.upload.guide'),
          buttons: [
            {
              title: t('common.button.confirm'),
              async onClick() {
                close();
              }
            }
          ]
        });
        setIsUploading(false);
        return;
      }

      const formData = new FormData();
      formData.append('file', file);

      const result = await mediaApi.postFileUpload(formData);

      const fileName = file.name;
      const resultFile = { ..._changeS3Form(result, file.type), fileName };

      testArray.push(resultFile);
    }
    setAttachments((prev) => [...prev, ...testArray]);
    setIsUploading(false);
  };

  return (
    <>
      {/* S : 파일 첨부하기 */}
      <div className="h-[calc(100%-52px)] pt-5">
        <div className="scroll-none h-full overflow-y-auto">
          {/* 첨부할 수없는 파일일 경우 Alert(Invalid file format. Cannot upload.)
            / Button : Confirm */}
          <FileUploader handleChange={handleDrop} name="file" multiple={multiple}>
            <div className="mx-auto flex w-full cursor-pointer flex-col items-center justify-center rounded-3 border border-gray-300 bg-gray-200 py-[43px]">
              <UploadIcon className="size-[60px]" />
              <div className="text-center text-16 font-semibold text-gray-700">
                {t('chat.attachPopup.upload.dragAndDrop')}
              </div>
            </div>
          </FileUploader>

          <div className="h-2" />
          <div className="flex items-center gap-1 text-13 text-gray-700">
            <InfoIcon className="" />
            {t('chat.attachPopup.upload.guide')}
          </div>

          {attachments?.length > 0 && (
            <div className="mb-2 mt-6 text-14 font-medium text-black-title">
              {t('chat.attachPopup.upload.uploadFiles')}
            </div>
          )}

          {attachments.map((attachment, index) => {
            const isImage = getIsImage(attachment.contentType);
            return (
              <React.Fragment key={index}>
                {/* 업로드 이미지가 1개일 때 */}
                <div className="flex w-full items-center border-b border-gray-200 bg-none p-0">
                  <div className={classNames({})}>
                    {isImage && <img className="size-10" src={attachment.cloudFrontUrl} />}
                    {!isImage && <FileIcon className="size-10" />}
                  </div>
                  <div className="ml-3 mr-5 block w-[170px]">
                    <div className="h-10 truncate pt-2.5 text-14">{attachment.fileName}</div>
                  </div>
                  <button
                    type="button"
                    className="rounded-full bg-gray-100 p-1 hover:bg-gray-400"
                    onClick={onRemoveFile(index)}
                  >
                    <CloseIcon className="size-3" />
                    <span className="sr-only">삭제</span>
                  </button>
                </div>
              </React.Fragment>
            );
          })}
          <div className="h-4" />
        </div>
      </div>

      <Button
        color="primary"
        size="lg"
        className="w-full text-center"
        onClick={onConfirm}
        disabled={attachments.length === 0}
      >
        {isUploading ? t('chat.attachPopup.button.uploading') : t('common.button.confirm')}
      </Button>
    </>
  );
};
export default ChatMessageAttachmentUpload;
