import React, { useCallback, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useOnClickOutside } from 'usehooks-ts';

import { ReactComponent as CoinIcon } from 'assets/icons/ic-chat-coin.svg';
import { ReactComponent as FileIcon } from 'assets/icons/ic-file.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/ic-plus.svg';
import { ReactComponent as VoteIcon } from 'assets/icons/ic-vote.svg';
import GroupSendCoinModal from 'components/wallet/modal/group-send-coin-modal';
import useAlert from 'routers/modal/useAlert';
import useModalStack from 'routers/modal/useModalStack';

import { useCurrentChannel } from 'hooks/use-current-channel';
import useUserMe from 'hooks/use-user-me';
import useMessageInput from 'hooks/useMessageInput';

import { isWebView } from 'utils/wallet';

interface ChatMessageInputMoreProps {
  isEditable: boolean;
}

const ChatMessageInputMore = ({ isEditable }: ChatMessageInputMoreProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { closeMemberSearch } = useMessageInput();

  const myInfo = useUserMe();

  const { data: currentChannel } = useCurrentChannel();

  const moreRef = useRef<HTMLDivElement>(null);
  const [showMore, setShowMore] = useState(false);

  const handleClick = () => {
    if (!isEditable) return;

    setShowMore(!showMore);
    closeMemberSearch();
  };

  const handleItemClick = useCallback(
    (path: string) => {
      if (!currentChannel) return;

      setShowMore(false);
      navigate(path);
    },
    [currentChannel, navigate]
  );

  const alert = useAlert();
  const modalStack = useModalStack();
  const openCoinModal = () => {
    switch (true) {
      case isWebView():
        if (!myInfo?.wallet_address_creta) {
          alert.open({
            contents: (
              <>
                <p className="text-16 text-black-body">
                  <Trans i18nKey="chat.sendCoin.block.alert.contents">
                    <span className="font-medium text-primary-100" />
                  </Trans>
                </p>
              </>
            ),
            submitText: t('chat.channelHeader.tooltip.setting'),
            submit: () => {
              // 임시로 일단 추가 ㅠㅡㅠ
              window.parent.postMessage(
                {
                  type: 'NAVIGATE',
                  path: '/user-profile/wallet/'
                },
                '*'
              );
            }
          });
        } else {
          modalStack.push({
            Component: GroupSendCoinModal,
            key: 'club-multi-send-coin-modal',
            componentProps: {
              channelId: currentChannel?.id,
              isDefaultCreta: isWebView() ? true : myInfo?.wallet_address === myInfo?.wallet_address_creta,
              singleSelect: isWebView()
            }
          });
        }
        break;

      default:
        if (!myInfo?.wallet_address) {
          alert.open({
            contents: (
              <>
                <p className="text-16 text-black-body">
                  <Trans i18nKey="chat.sendCoin.block.alert.contents">
                    <span className="font-medium text-primary-100" />
                  </Trans>
                </p>
              </>
            ),
            submitText: t('chat.channelHeader.tooltip.setting'),
            submit: () => {
              window.open(`${process.env.REACT_APP_MAIN_URL}/user-profile/wallet/`);
            }
          });
          return;
        }

        modalStack.push({
          Component: GroupSendCoinModal,
          key: 'club-multi-send-coin-modal',
          componentProps: {
            channelId: currentChannel?.id,
            isDefaultCreta: isWebView() ? true : myInfo?.wallet_address === myInfo?.wallet_address_creta,
            singleSelect: isWebView()
          }
        });
    }
  };

  useOnClickOutside(moreRef, () => setShowMore(false));

  return (
    <div ref={moreRef} className="absolute bottom-1.5 left-2">
      {showMore && (
        <ul className="absolute bottom-11 z-10 w-fit min-w-32 rounded-2 border border-gray-300 bg-white py-2">
          <li
            onClick={() => handleItemClick('attach')}
            className="flex cursor-pointer items-center gap-0.5 px-3 py-1.5 hover:bg-gray-100"
          >
            <FileIcon className="size-6" />
            <span className="whitespace-nowrap break-all text-14 text-black-title">
              {t('chat.inputMore.label.files')}
            </span>
          </li>
          <li
            onClick={() => handleItemClick('poll/create')}
            className="flex cursor-pointer items-center gap-0.5 px-3 py-1.5 hover:bg-gray-100"
          >
            <VoteIcon className="size-6" />
            <span className="whitespace-nowrap break-all text-14 text-black-title">
              {t('chat.inputMore.label.poll')}
            </span>
          </li>
          {/* DM 채널이 아닐 때만 코인 전송 메뉴 보이기 */}
          {currentChannel?.customType === 'GROUP' && (
            <li
              onClick={() => openCoinModal()}
              className="flex cursor-pointer items-center gap-0.5 px-3 py-1.5 hover:bg-gray-100"
            >
              <CoinIcon className="size-6" />
              <span className="whitespace-nowrap break-all text-14 text-black-title">
                {t('chat.inputMore.label.coins')}
              </span>
            </li>
          )}
        </ul>
      )}
      <button
        type="button"
        className="flex size-8 items-center justify-center rounded-full bg-gray-400 text-white hover:bg-black-title active:bg-black-title"
        disabled={!isEditable}
        onClick={handleClick}
      >
        <PlusIcon className="size-6" />
      </button>
    </div>
  );
};

export default ChatMessageInputMore;
