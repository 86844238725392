import { getStorage, setStorage } from './storage';

import { AuthStorage } from 'constants/storage';

export const getAccessToken = () => {
  return getStorage(AuthStorage.ACCESS_TOKEN) || '';
};

export const setAccessToken = (accessToken: string) => {
  setStorage(AuthStorage.ACCESS_TOKEN, accessToken);
};

/**
 * 채널 URL에서 채널 ID를 추출합니다.
 * 기존 채널 핸들링들 url로 하고 있었는데, 슈퍼클럽 채팅 API에서는 id로 관리하고 있어서
 * 추후 GET 요청들을 슈퍼클럽 채팅 API로 변경하기 전까지 임시로 사용합니다.
 */
export const getChannelIdByChannelUrl = (channelUrl?: string) => {
  if (!channelUrl) return '';
  // sendbird_group_channel_ 뒤에 있는 숫자가 채널 ID입니다.
  // sendbird_group_channel_ 로 split 못하는 경우 _로 split 하고 -2 부터 slice 합니다.

  if (channelUrl.includes('sendbird_group_channel_')) {
    const splitChannelUrl = channelUrl.split('sendbird_group_channel_');
    return splitChannelUrl[1];
  }

  const splitByUnderscore = channelUrl.split('_');
  return splitByUnderscore.slice(-2).join('_');
};

/**
 * 현재 OS가 Mac인지 확인하는 함수
 *
 *
 */
export const isMac = () => navigator.userAgent.includes('Macintosh') || navigator.platform.includes('Mac');
